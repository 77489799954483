import { IAppBreadcrumbItem } from 'components/breadcrumb/AppBreadcrumb';
import { ICart } from 'interfaces/cart.interface';
import create from 'zustand';

interface CartState {
  cart: ICart | null;
  setCart: (payload: ICart | null) => void;
}

export const useCartStore = create<CartState>((set) => ({
  cart: null,
  setCart: (payload: ICart | null) => set({ cart: payload }),
}));
