import axios from 'axios';

// Set config defaults when creating the instance
const _axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
});

_axios.defaults.headers.common.Authorization = '';

// Add a request interceptor
_axios.interceptors.request.use(
  function (config) {
    if (config.headers) {
      config.headers['x-custom-lang'] = 'th';
    }
    console.debug('AxiosRequest Url', config.method + '-' + config.url);
    // console.log('AxiosRequest Headers', config.headers?.common);
    console.debug(`AxiosRequest ${config.method}-${config.url}`, config.data);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(function (response) {
  console.log(
    `AxiosResponse ${response.config.method}-${
      response.config.url
    } ${new Date().toLocaleTimeString()}`,
    response.data
  );
  return response;
});

export default _axios;
