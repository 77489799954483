import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import {
  AspectRatio,
  Box,
  Button,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react';
import InputNumber from 'components/inputs/InputNumber';
import UtilPriceFormatter from 'components/utils/UtilPriceFormatter';
import useCart from 'hooks/useCart';
import useCatchError from 'hooks/useCatchError';
import useProduct from 'hooks/useProduct';
import useProductItem from 'hooks/useProductItem';
import useUser from 'hooks/useUser';
import { ICartItem } from 'interfaces/cart.interface';
import { debounce } from 'lodash';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CartRepository } from 'respository/cart.repository';

type MiniCartItemProps = {
  item: ICartItem;
};

function MiniCartItem({ item }: MiniCartItemProps) {
  // use i18n
  const { t } = useTranslation(['common']);

  // use user
  const { user } = useUser();

  // use catch error
  const catchError = useCatchError();

  // use cart
  const { cartItemDelete, cartItemQuantityUpdate } = useCart();

  // init cart remove loading
  const [isCartRemoveLoading, setIsCartRemoveLoading] =
    useState<boolean>(false);

  // use router
  const router = useRouter();

  const { featuredImage, name: productName } = useProductItem({
    product: item.product,
    variation: item.variation,
  });

  // init is remove cart item
  const [isRemoveCartItem, setIsRemoveCartItem] = useState<boolean>(false);

  // init quantity
  const [quantity, setQuantity] = useState<number>(item.quantity);

  // get variation name
  const variationName = useMemo(() => {
    if (item.attributeOptions && item.attributeOptions.length > 0) {
      return item.attributeOptions
        .map((attributeOption) => {
          return router.locale === 'th'
            ? attributeOption.name_th
            : attributeOption.name_en;
        })
        .join(' ');
    }

    return '';
  }, [item.attributeOptions]);

  const handleCartItemQuantityUpdate = async (
    key: string,
    quantity: number
  ) => {
    try {
      if (user && user.isAuthenticated) {
        await CartRepository.updateQuantity({
          key,
          quantity,
        });
      }

      cartItemQuantityUpdate(item.key, quantity);
    } catch (error) {
      catchError(error);
    }
  };

  const debouncedUpdateQuantity = useRef(
    debounce(handleCartItemQuantityUpdate, 1000)
  ).current;

  const handleCartRemoveItem = async () => {
    try {
      setIsRemoveCartItem(true);
      setIsCartRemoveLoading(true);

      if (user && user.isAuthenticated) {
        await CartRepository.removeItem(item.key);
      }

      cartItemDelete(item.key);
      setIsRemoveCartItem(false);
    } catch (error) {
      catchError(error);
    } finally {
      setIsCartRemoveLoading(false);
    }
  };

  return (
    <HStack alignItems="flex-start">
      <Box boxSize="60px" flex="0 0 auto">
        <AspectRatio ratio={1}>
          <Image
            src={featuredImage}
            alt="featuredImage"
            width={60}
            height={60}
          ></Image>
        </AspectRatio>
      </Box>
      <Box flex="1">
        <Heading
          fontSize="md"
          fontWeight={400}
          textTransform="uppercase"
          mb={1}
        >
          {productName}
        </Heading>
        {variationName !== '' && (
          <Text fontSize="sm" mb={1}>
            {variationName}
          </Text>
        )}
        <HStack>
          <Text fontSize="xs">
            {t('form.label.quantity', {
              ns: 'common',
            })}
          </Text>
          <Box flex="0 0 70px">
            <InputNumber
              size="xs"
              step={1}
              min={1}
              max={item.maxQuantity}
              value={quantity}
              onChange={(valueAsString, valueAsNumber) => {
                setQuantity(valueAsNumber);
                debouncedUpdateQuantity(item.key, valueAsNumber);
              }}
            ></InputNumber>
          </Box>
        </HStack>
      </Box>
      <Box flex="0 0 auto" justifySelf="flex-end" textAlign="right">
        {isRemoveCartItem ? (
          <HStack justifyContent="flex-end" width="100%">
            <Button
              variant="unstyled"
              p={0}
              minW="0"
              height="auto"
              color="primary.500"
              isLoading={isCartRemoveLoading}
              onClick={handleCartRemoveItem}
            >
              <CheckOutlined></CheckOutlined>
            </Button>
            <Button
              variant="unstyled"
              p={0}
              minW="0"
              height="auto"
              color="gray.500"
              onClick={() => {
                setIsRemoveCartItem(false);
              }}
            >
              <CloseOutlined></CloseOutlined>
            </Button>
          </HStack>
        ) : (
          <Button
            variant="unstyled"
            p={0}
            minW="0"
            height="auto"
            onClick={() => {
              setIsRemoveCartItem(true);
            }}
          >
            <Image
              src="/image-icon/trash.png"
              alt="trash"
              width={13}
              height={18}
              quality="100"
            ></Image>
          </Button>
        )}
        {item.salePrice ? (
          <Box>
            <Text fontSize="sm" color="gray.500" as="s">
              <UtilPriceFormatter
                price={item.regularPrice}
              ></UtilPriceFormatter>
            </Text>
            <Text fontWeight="500" fontSize="17px">
              <UtilPriceFormatter price={item.salePrice}></UtilPriceFormatter>
            </Text>
          </Box>
        ) : (
          <Text fontWeight="500" fontSize="17px">
            <UtilPriceFormatter price={item.regularPrice}></UtilPriceFormatter>
          </Text>
        )}
      </Box>
    </HStack>
  );
}

export default MiniCartItem;
